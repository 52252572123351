import React, { Component } from "react";
import Img from "gatsby-image";

export default class Banner extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="banner">
        <Img
          fluid={data.bannerImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="Blockchain wallpaper"
        />
        <div className="container">
          <div className="banner-details">
            <span
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-easing="easeInOutSine"
              data-sal-duration="1000"
            >
              Miquel Trallero
            </span>
            <h1
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-easing="easeInOutSine"
              data-sal-duration="1000"
            >
              {data.designation}
            </h1>            
            
            <ul
              className="sub-data"
              data-sal="fade"
              data-sal-delay="600"
              data-sal-easing="easeInOutSine"
              data-sal-duration="1000"
            >
              {data.bannerList.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            <ul className="social">
              <li>
                <a
                  className="fab fa-linkedin-in"
                  data-sal="fade"
                  data-sal-delay="1000"
                  data-sal-easing="easeInOutSine"
                  data-sal-duration="1000"
                  href={data.linkdin}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-github"
                  data-sal="fade"
                  data-sal-delay="1000"
                  data-sal-easing="easeInOutSine"
                  data-sal-duration="1000"
                  href={data.github}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
