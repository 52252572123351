import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2
              className="text-center"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-easing="easeInOutSine"
              data-sal-duration="600"
            >
              CONTACT
            </h2>
          </div>
          <form
            action={`https://formspree.io/mdoyydlq`}
            // name="Contact"
            method="POST"
            data-netlify="true"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="easeInOutSine"
            data-sal-duration="600"
          >
            <div>
              <label>
                Your Name: <input type="text" name="name" required />
              </label>
            </div>
            <div>
              <label>
                Your Email: <input type="email" name="email" required />
              </label>
            </div>
            <div>
              <label>
                Message: <textarea name="message" required></textarea>
              </label>
            </div>
            <div>
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
