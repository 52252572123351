import React, { Component } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

export default class work extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="section" id="Projects">
        <div className="container">
          <div className="section-head">
            <h2
              className="text-center"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-easing="easeInOutSine"
              data-sal-duration="600"
             >
               PROJECTS
            </h2>
          </div>
          <ul className="work-list">
            {data.edges.map((item, index) => {
              return (
                <li key={index} className="item">
                  <div 
                    className="item__info"
                    data-sal="slide-right"
                    data-sal-delay="200"
                    data-sal-easing="eeaseInOutSine"
                    data-sal-duration="600"
                  >
                    <h3 className="project-name">{item.node.siteName}</h3>                    
                    {item.node.description.description}
                    <div className="project-buttons">
                      <div className="see-more">
                        <a href={item.node.url} target="_blank" rel="noopener noreferrer">
                          <span>See Live</span>
                        </a>
                      </div>
                      <div className="source-code">
                        <a href={item.node.sourceCode} target="_blank" rel="noopener noreferrer">
                          <span>Source Code</span>
                        </a>
                      </div>
                    </div>
                      
                  </div>                   
                  <div 
                    className="inner"
                    data-sal="slide-left"
                    data-sal-delay="600"
                    data-sal-easing="easeInOutSine"
                    data-sal-duration="600"
                  >                  
                    <a href={item.node.url} target="_blank" rel="noopener noreferrer">
                      <Img
                        fluid={item.node.image.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={item.node.siteName}
                      />
                      {/* <span className="name">{item.node.siteName}</span> */}
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
